import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Guide } from '@sales-libs/sc-onboarding/data-access';
import { ScSharedActions, ScSharedSelectors } from '@sales-libs/shared/feature';
import {
  deformatUrlFriendlyName,
  formatUrlFriendlyName,
  removeSpecialChars,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, switchMap, take } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SlScOnboardingGuard {
  private readonly defaultLanguageCode = 'EN';
  productLineEnum: { [key: string]: string } = {};
  productLineName: string | undefined;
  userGuides$: Observable<Guide[]>;
  userGuide$: Observable<Guide>;
  guides: Guide[];
  guide: Guide | undefined;
  guideName: string;
  displaySpotlight: boolean | undefined;

  constructor(
    private router: Router,
    private store: Store,
    private destroyRef: DestroyRef,
  ) {
    this.store.dispatch(ScSharedActions.resetState());
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const urlProductLineParam = route.paramMap.get('productLine');
    const urlLanguageParam = route.paramMap.get('language');

    this.productLineName = urlProductLineParam ?? '';

    this.store.dispatch(
      ScSharedActions.getUserGuides({
        language: urlLanguageParam ?? this.defaultLanguageCode,
      }),
    );

    return this.store.pipe(
      select(ScSharedSelectors.userGuides),
      filterTruthy(),
      take(1),
      switchMap((guides) => {
        this.guides = guides;
        this.guide = guides.find(
          (guide) =>
            removeSpecialChars(guide.name?.toLowerCase()) ===
            deformatUrlFriendlyName(this.productLineName)?.toLowerCase(),
        );
        this.guideName = formatUrlFriendlyName(this.guide?.name) ?? '';
        if (this.guide?.settings?.display_maintenance_page === true) {
          this.navigateToMaintenancePage();
          return [false];
        } else {
          return [true];
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  navigateToMaintenancePage() {
    this.router.navigateByUrl(
      `/${this.defaultLanguageCode}/${this.guideName}/maintenance`,
    );
  }
}
