<div class="sl-sc-wrapper">
  <div class="onboarding-wrap" fxLayout="column">
    <div
      class="onboarding-wrap__logo-language"
      fxLayoutAlign="space-between center"
    >
      <img
        class="logo"
        src="assets/img/logo.svg"
        routerLink="/"
        alt="PALFINGER logo"
        priority
        height="30"
        width="125"
      />
      <div
        *ngIf="supportedLanguages$ | async as supportedLanguages"
        [class]="
          supportedLanguages.data !== undefined &&
          supportedLanguages.data !== null &&
          supportedLanguages.data.length <= 1
            ? 'upper-wrap__btn-container-disabled'
            : ''
        "
      >
        <button
          mat-button
          class="upper-wrap__btn"
          [matMenuTriggerFor]="languageMenu"
          [disabled]="
            supportedLanguages.data !== undefined &&
            supportedLanguages.data !== null &&
            supportedLanguages.data.length <= 1
          "
        >
          {{ selectedLanguage.display_name }}
          <mat-icon>language</mat-icon>
        </button>
        <mat-menu #languageMenu="matMenu">
          <button
            *ngFor="let supportedLanguage of supportedLanguages.data"
            mat-menu-item
            disableRipple="true"
            (click)="
              $event.preventDefault();
              changeUserLanguage(
                supportedLanguage.code ?? '',
                supportedLanguage.native_name ?? ''
              )
            "
          >
            {{ supportedLanguage.native_name }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div
      class="onboarding-wrap__background"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
    >
      <div class="upper-wrap__text" fxFlex.sm="50">
        <h3 class="h3">
          {{ 'turnkey_solution.onboarding.caption' | translate }}
        </h3>
        <h1>{{ mainTitle }}</h1>
        <p class="upper-wrap__text-details">
          {{ mainDescription }}
        </p>
        <ng-container *ngIf="userGuide$ | async as userGuide">
          <button
            mat-raised-button
            color="accent"
            class="start-button"
            (click)="goToQuestionnaire(userGuide)"
            [dsButtonPending]="loadingButton[userGuide.id]"
            disableRipple="true"
          >
            {{ 'turnkey_solution.onboarding.start_configuration' | translate }}
          </button>
        </ng-container>

        <div fxLayout="column" fxLayoutAlign="center flex-start">
          <h5>
            {{ 'turnkey_solution.onboarding.code_description' | translate }}
          </h5>
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="retrieve-configuration-wrap"
          >
            <mat-form-field>
              <mat-label>
                {{ 'turnkey_solution.onboarding.enter_code' | translate }}
              </mat-label>
              <input matInput [(ngModel)]="onlineCode" />
            </mat-form-field>
            <button
              mat-stroked-button
              class="btn-retrieve-configuration"
              data-cy="btn-retrieve-configuration"
              [disabled]="!onlineCode"
              (click)="openConfiguration(onlineCode)"
              [gaClickListener]="
                Analytics.RetrieveConfiguration + ' ' + onlineCode
              "
            >
              {{
                'turnkey_solution.onboarding.retrieve_configuration' | translate
              }}
            </button>
          </div>
        </div>
      </div>

      @if (
        productLineName?.toLowerCase() ===
        productLineEnum?.ShortTimber?.toLowerCase()
      ) {
        <video
          *ngIf="userGuide$ | async as userGuide"
          (play)="playVideo()"
          #videoPlayer
          class="upper-wrap__video"
          autoplay="autoplay"
          loop
          onloadstart="this.volume=0.2"
        >
          <source src="{{ userGuide.image_uri }}" type="video/mp4" />
          {{ 'turnkey_solution.common.video_not_supported' | translate }}
        </video>
      } @else {
        <img
          *ngIf="userGuide$ | async as userGuide"
          class="upper-wrap__img"
          src="{{ userGuide.image_uri }}"
          alt="Product Line"
          priority
        />
      }
    </div>

    <div>
      <div class="onboarding-wrap text-center" fxFlex="1 1 auto">
        <h2 class="sl-sc-title">
          {{ 'turnkey_solution.onboarding.steps_title' | translate }}?
        </h2>

        <div
          fxLayout="row wrap"
          fxLayoutAlign="center flex-start"
          fxLayoutAlign.lt-md="stretch"
        >
          <mat-card
            *ngFor="let cardData of data"
            class="sl-sc-card"
            fxLayout="column wrap"
            fxLayoutAlign="center center"
          >
            <mat-card-content>
              <div class="sl-sc-card-icon">
                <ng-container
                  *ngIf="cardData.icon !== 'local_shipping'; else truckIcon"
                >
                  <span class="material-icons">{{ cardData.icon }}</span>
                </ng-container>

                <ng-template #truckIcon>
                  <mat-icon
                    fontSet="pd"
                    fontIcon="pd-local_shipping_outlined"
                  ></mat-icon>
                </ng-template>
              </div>
              <h4>{{ cardData.title | translate }}</h4>
              <p>{{ cardData.description | translate }}</p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div
      class="info-wrap text-center"
      *ngIf="productLine === urlFriendlyProductLineEnum.TailLift"
    >
      <h3 class="h3">
        {{ 'turnkey_solution.onboarding.more_info.title' | translate }}
      </h3>
      <p>
        {{ 'turnkey_solution.onboarding.more_info.description_1' | translate }}
      </p>
      <p>
        {{ 'turnkey_solution.onboarding.more_info.description_2' | translate }}
      </p>
      <p>
        {{ 'turnkey_solution.onboarding.more_info.description_3' | translate }}
      </p>
    </div>
  </div>
</div>
