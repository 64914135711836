import { Action, createReducer, on } from '@ngrx/store';
import { SlScOnboardingActions } from './onboarding.actions';
import { initialState, SlScOnboardingState } from './onboarding.store';

export const slScOnboardingReducer = createReducer(
  initialState,

  on(
    SlScOnboardingActions.getSections,
    SlScOnboardingActions.createUserGuid,
    (state, { guideId, language }) => ({
      ...state,
      guideId: guideId,
      language: language,
    }),
  ),
  on(SlScOnboardingActions.getSectionsSuccess, (state, { sections }) => ({
    ...state,
    sections: sections,
  })),
  on(SlScOnboardingActions.getSectionsError, (state, { payload }) => ({
    ...state,
    gettingSectionsError: payload,
  })),

  on(
    SlScOnboardingActions.createUserGuidSuccess,
    (state, { personal_guide }) => ({
      ...initialState,
      userGuid: personal_guide,
    }),
  ),
  on(SlScOnboardingActions.createUserGuidError, (state, { payload }) => ({
    ...state,
    gettingUserGuidError: payload,
  })),

  on(SlScOnboardingActions.resetState, () => ({
    ...initialState,
  })),

  on(SlScOnboardingActions.getUserGuideData, (state, { onlineCode }) => ({
    ...state,
    onlineCode: onlineCode,
  })),
  on(SlScOnboardingActions.getUserGuideDataSuccess, (state, { userGuide }) => ({
    ...state,
    userGuide: userGuide,
  })),
  on(SlScOnboardingActions.getUserGuideDataError, (state, { payload }) => ({
    ...state,
    gettingSectionsError: payload,
  })),
);

export function reducer(
  state: SlScOnboardingState | undefined,
  action: Action,
) {
  return slScOnboardingReducer(state, action);
}
