<div class="sl-sc-wrapper general-main">
  <div class="onboarding-wrap" fxLayout="column">
    <div
      class="onboarding-wrap__logo-language"
      fxLayoutAlign="space-between center"
    >
      <img
        class="logo"
        src="assets/img/logo.svg"
        routerLink="/"
        alt="PALFINGER logo"
        priority
        height="30"
        width="125"
      />
      <div
        *ngIf="supportedLanguages$ | async as supportedLanguages"
        [class]="
          supportedLanguages.data !== undefined &&
          supportedLanguages.data !== null &&
          supportedLanguages.data.length
            ? 'upper-wrap__btn-container-disabled'
            : ''
        "
      >
        <button
          mat-button
          class="upper-wrap__btn"
          [matMenuTriggerFor]="languageMenu"
          [disabled]="
            supportedLanguages.data !== undefined &&
            supportedLanguages.data !== null &&
            supportedLanguages.data.length <= 1
          "
          data-cy="language-menu"
        >
          {{ selectedLanguage.display_name }}
          <mat-icon>language</mat-icon>
        </button>
        <mat-menu #languageMenu="matMenu">
          <button
            *ngFor="let supportedLanguage of supportedLanguages.data"
            mat-menu-item
            disableRipple="true"
            (click)="
              $event.preventDefault();
              changeUserLanguage(
                supportedLanguage.code ?? '',
                supportedLanguage.native_name ?? ''
              )
            "
            data-cy="language-menu-item"
          >
            {{ supportedLanguage.native_name }}
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="hero__wrap">
      <div
        class="hero full-height"
        fxLayoutAlign="flex-start center"
        fxLayout.lt-sm="column"
      >
        <div class="hero__text" fxLayout="column" data-cy="landing-headline">
          <h3 class="h3">
            {{ 'turnkey_solution.onboarding.caption' | translate }}
          </h3>
          <h1>
            {{ 'turnkey_solution.onboarding.general.main_title' | translate }}
          </h1>
          <p class="hero__description">
            {{ 'turnkey_solution.onboarding.general.description' | translate }}
          </p>

          <div fxLayout="column" fxLayoutAlign="center flex-start">
            <h5>
              {{ 'turnkey_solution.onboarding.code_description' | translate }}
            </h5>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              class="retrieve-configuration-wrap"
            >
              <mat-form-field>
                <mat-label>
                  {{ 'turnkey_solution.onboarding.enter_code' | translate }}
                </mat-label>
                <input matInput [(ngModel)]="onlineCode" />
              </mat-form-field>
              <button
                mat-stroked-button
                class="btn-retrieve-configuration"
                data-cy="btn-retrieve-configuration"
                [dsButtonPending]="loadingButton[onlineCode]"
                [disabled]="this.isButtonDisabled || !onlineCode"
                (click)="openConfiguration(onlineCode)"
                [gaClickListener]="
                  Analytics.RetrieveConfiguration + ' ' + onlineCode
                "
              >
                {{
                  'turnkey_solution.onboarding.retrieve_configuration'
                    | translate
                }}
              </button>
            </div>
          </div>
        </div>
        <img
          class="hero__img"
          src="assets/img/home.jpeg"
          routerLink="/"
          alt="PALFINGER configuration"
          priority
          height="900"
          width="1400"
        />
      </div>
    </div>

    <div class="product-lines-wrap">
      <div class="content-wrap">
        <h2 class="sl-sc-subtitle">
          {{ 'turnkey_solution.onboarding.general.product_lines' | translate }}
        </h2>
        <p>
          {{
            'turnkey_solution.onboarding.general.product_lines_description'
              | translate
          }}
        </p>

        <div
          *ngIf="userGuides$ | async as userGuides"
          fxLayout="row wrap"
          fxLayoutAlign="flex-start"
          class="sl-sc-product-container-wrap"
        >
          <mat-card
            *ngFor="let userGuide of userGuides"
            class="sl-sc-product-container"
          >
            <mat-card-content
              fxLayout="column wrap"
              fxLayoutAlign="center center"
              [ngSwitch]="userGuide.name"
            >
              <ds-equipment-icon
                *ngSwitchCase="productLineEnum.TimberCrane"
                type="010"
                [width]="80"
                [maxHeight]="100"
                class="sl-sc-product-icon"
              ></ds-equipment-icon>
              <ds-equipment-icon
                *ngSwitchCase="productLineEnum.ShortTimber"
                type="010"
                [width]="80"
                [maxHeight]="100"
                class="sl-sc-product-icon"
              ></ds-equipment-icon>
              <ds-equipment-icon
                *ngSwitchCase="productLineEnum.HookAndSkipLoaders"
                type="007"
                [width]="100"
                [maxHeight]="100"
                class="sl-sc-product-icon"
              ></ds-equipment-icon>
              <ds-equipment-icon
                *ngSwitchCase="productLineEnum.AccessPlatforms"
                type="003"
                [width]="90"
                [maxHeight]="100"
                class="sl-sc-product-icon"
              ></ds-equipment-icon>
              <ds-equipment-icon
                *ngSwitchCase="productLineEnum.TruckMountedForklifts"
                type="004"
                [width]="90"
                [maxHeight]="100"
                class="sl-sc-product-icon"
              ></ds-equipment-icon>
              <ds-equipment-icon
                *ngSwitchDefault
                type="006"
                [width]="100"
                [maxHeight]="100"
                class="sl-sc-product-icon"
              ></ds-equipment-icon>
              <h4 class="text-center">{{ userGuide.name }}</h4>
              <button
                mat-raised-button
                color="accent"
                class="btn-start-configurator"
                data-cy="btn-start-configurator"
                [dsButtonPending]="loadingButton[userGuide.id]"
                [disabled]="this.isButtonDisabled"
                (click)="startConfigurator(userGuide)"
                [gaClickListener]="
                  Analytics.StartConfigurator + ' ' + userGuide.name
                "
              >
                {{
                  'turnkey_solution.onboarding.general.start_configurator'
                    | translate
                }}
              </button>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="how-it-works-wrap">
      <div class="content-wrap">
        <h2 class="sl-sc-subtitle">
          {{ 'turnkey_solution.onboarding.general.how_it_works' | translate }}
        </h2>
        <mat-card>
          <mat-card-content fxLayout="row wrap" fxLayoutAlign="space-around">
            <div
              *ngFor="let cardData of data"
              class="sl-sc-card"
              fxLayout="column wrap"
              fxLayoutAlign="center center"
            >
              <div class="sl-sc-card-icon" fxLayoutAlign="center center">
                <ng-container
                  *ngIf="cardData.icon !== 'local_shipping'; else truckIcon"
                >
                  <span class="material-icons">{{ cardData.icon }}</span>
                </ng-container>
                <ng-template #truckIcon>
                  <mat-icon
                    fontSet="pd"
                    fontIcon="pd-local_shipping_outlined"
                  ></mat-icon>
                </ng-template>
              </div>
              <h4>{{ cardData.title | translate }}</h4>
              <p>{{ cardData.description | translate }}</p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
