import {
  Guid,
  Sections,
  UserGuide,
} from '@sales-libs/sc-onboarding/data-access';
import { Guides, Languages } from '@sales-libs/shared/data-access/sc-generated';

export interface SlScOnboardingState {
  userGuides?: Guides;
  languages?: Languages;
  sections: Sections;
  userGuid?: Guid;
  userGuide?: UserGuide;
}

export const initialState: SlScOnboardingState = {
  userGuides: undefined,
  languages: undefined,
  sections: {} as Sections,
  userGuid: undefined,
  userGuide: undefined,
};
