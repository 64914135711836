import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsLoadingModule } from '@design-system/components/loading';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { SlScGeneralOnboardingComponent } from './general/general-onboarding.component';
import { SlScGeneralOnboardingGuard } from './guards/sc-general-onboarding.guard';
import { SlScOnboardingGuard } from './guards/sc-onboarding.guard';
import { SlScOnboardingComponent } from './onboarding.component';
import { SlScOnboardingEffects } from './store/onboarding.effects';
import { slScOnboardingReducer } from './store/onboarding.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('SlScOnboardingState', slScOnboardingReducer),
    EffectsModule.forFeature([SlScOnboardingEffects]),
    CommonModule,
    DsEquipmentIconModule,
    DsSpacingModule,
    DsLoadingModule,
    RouterModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleAnalytics4DirectiveModule,
  ],
  declarations: [SlScOnboardingComponent, SlScGeneralOnboardingComponent],
  exports: [SlScOnboardingComponent, SlScGeneralOnboardingComponent],
  providers: [SlScOnboardingGuard, SlScGeneralOnboardingGuard],
})
export class SlScOnboardingModule {}
