import { createAction, props } from '@ngrx/store';
import {
  Guid,
  ProblemDetails,
  Sections,
  UserGuide,
} from '@sales-libs/sc-onboarding/data-access';

export namespace SlScOnboardingActions {
  export const getSections = createAction(
    '[Onboarding] GET_SECTIONS',
    props<{ guideId: number; language: string }>(),
  );
  export const getSectionsSuccess = createAction(
    '[Onboarding] GET_SECTIONS_SUCCESS',
    props<{ sections: Sections }>(),
  );
  export const getSectionsError = createAction(
    '[Onboarding] GET_SECTIONS_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const createUserGuid = createAction(
    '[Onboarding] GET_USER_GUID',
    props<{ guideId: number; language: string }>(),
  );
  export const createUserGuidSuccess = createAction(
    '[Onboarding] GET_USER_GUID_SUCCESS',
    props<{ personal_guide: Guid }>(),
  );
  export const createUserGuidError = createAction(
    '[Onboarding] GET_USER_GUID_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const getUserGuideData = createAction(
    '[Onboarding] GET_USER_GUIDE_DATA',
    props<{ onlineCode: string }>(),
  );
  export const getUserGuideDataSuccess = createAction(
    '[Onboarding] GET_USER_GUIDE_DATA_SUCCESS',
    props<{ userGuide: UserGuide }>(),
  );
  export const getUserGuideDataError = createAction(
    '[Onboarding] GET_USER_GUIDE_DATA_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const resetState = createAction('[Onboarding] RESET_STATE');
}
