/* eslint-disable max-lines */
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  Guide,
  Metadata,
  UserGuide,
} from '@sales-libs/sc-onboarding/data-access';
import { Languages } from '@sales-libs/shared/data-access/sc-generated';
import { ScSharedActions, ScSharedSelectors } from '@sales-libs/shared/feature';
import {
  Analytics,
  MetaTagsService,
  SectionType,
  deformatUrlFriendlyName,
  formatUrlFriendlyName,
  populateProductLineEnum,
  populateUrlFriendlyProductLineEnum,
  removeSpecialChars,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, Subject, map, take, takeUntil } from 'rxjs';
import { SlScOnboardingActions } from './store/onboarding.actions';

@Component({
  selector: 'sl-sc-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class SlScOnboardingComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @Input() enableGeneralOnboardingScreen: boolean;
  @Output() languageSelectionChange: EventEmitter<string> = new EventEmitter();
  userGuid: string;
  userGuide$: Observable<Guide>;
  userGuides$: Observable<Guide[]>;
  userGuides: Guide[];
  supportedLanguages$: Observable<Languages>;
  isLanguageSupported: boolean;
  selectedLanguage: { code: string; display_name: string };
  urlLanguageCode: string;
  defaultLanguage = {
    code: 'EN',
    english_name: 'English',
    native_name: 'English',
  };
  firstSectionName: string;
  loadingButton: { [id: number]: boolean } = {};
  productLine: string | null;
  productLineName: string | null;
  mainTitle: string;
  mainDescription: string;
  guideId: number;
  metadata: Metadata;
  gdprConsent = 'GDPRConsentGiven';
  guideKey: string;
  onlineCode: string;
  sectionType = SectionType.Configuration;

  data = [
    {
      icon: 'help',
      title: 'turnkey_solution.onboarding.step_1.title',
      description: 'turnkey_solution.onboarding.step_1.description',
    },
    {
      icon: 'local_shipping',
      title: 'turnkey_solution.onboarding.step_2.title',
      description: 'turnkey_solution.onboarding.step_2.description',
    },
    {
      icon: 'build',
      title: 'turnkey_solution.onboarding.step_3.title',
      description: 'turnkey_solution.onboarding.step_3.description',
    },
  ];

  readonly Analytics = Analytics;
  private readonly destroy$ = new Subject<void>();
  productLineEnum: { [key: string]: string } = {};
  urlFriendlyProductLineEnum: { [key: string]: string } = {};

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    public readonly route: ActivatedRoute,
    private readonly metaTagsService: MetaTagsService,
    private readonly actions: Actions,
    private readonly _ngZone: NgZone,
    private readonly snackbar: DsSnackbar,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit() {
    this.getGuide();
    this.getProductLineEnum();
    this.getGuideIdentifier();
    this.setPageMetadata();
    this.setPageTitleAndDescription();
  }

  setPageMetadata() {
    this.userGuide$.pipe(filterTruthy(), take(1)).subscribe((guide) => {
      this.metadata = {
        description: guide.metadata?.description,
        title: guide.metadata?.title,
        keywords: guide.metadata?.keywords,
      };

      this.metaTagsService.setMetaTitleDescriptionKeywords(
        this.metadata.title,
        this.metadata.description,
        this.metadata.keywords,
      );
    });
  }

  getProductLineNameFromUrl(): void {
    this.route?.params.pipe(take(1)).subscribe((event) => {
      if (
        Object.values(this.productLineEnum)
          ?.map((value) => value.toLowerCase())
          .includes(
            deformatUrlFriendlyName(event.productLine)?.toLowerCase() ?? '',
          )
      ) {
        this.productLine = event.productLine;
        this.productLineName = deformatUrlFriendlyName(event.productLine);
      } else {
        this._ngZone.run(() => {
          this.router.navigate(['/special']);
        });
      }
    });
  }

  getProductLineEnum(): void {
    this.userGuides$ = this.store.pipe(
      select(ScSharedSelectors.userGuides),
      filterTruthy(),
    );

    this.userGuides$.pipe(takeUntil(this.destroy$)).subscribe((guides) => {
      this.userGuides = guides;
      this.productLineEnum = populateProductLineEnum(guides);
      this.getProductLineNameFromUrl();
      this.urlFriendlyProductLineEnum =
        populateUrlFriendlyProductLineEnum(guides);
    });
  }

  setMainTitle(): void {
    this.translateService
      .stream('turnkey_solution.onboarding.main_title')
      .subscribe((mainTitleTranslation: string) => {
        this.mainTitle = mainTitleTranslation.replace(
          '{0}',
          this.productLineName ?? '',
        );
      });
  }

  setMainDescription(): void {
    this.translateService
      .stream('turnkey_solution.onboarding.main_description.' + this.guideKey)
      .subscribe((mainDescriptionTranslation: string) => {
        this.mainDescription = mainDescriptionTranslation;
      });
  }

  getGuides(): void {
    this.store.dispatch(
      ScSharedActions.getUserGuides({
        language:
          this.selectedLanguage?.code ??
          this.translateService.currentLang ??
          this.defaultLanguage.code,
      }),
    );
  }

  getGuide(): void {
    this.userGuide$ = this.store.pipe(
      select(ScSharedSelectors.userGuides),
      map((guides) => this.getSelectedGuide(guides ?? [])),
      filterTruthy(),
    );
  }

  getSelectedGuide(guides: Guide[]): Guide | undefined {
    return guides?.find((guide) =>
      this.guideId > 0
        ? guide.id === this.guideId
        : removeSpecialChars(guide.name?.toLowerCase()) ===
          this.productLineName?.toLowerCase(),
    );
  }

  setupLanguages(): void {
    this.getLanguages();
    this.preselectLanguage();
  }

  getGuideIdentifier() {
    this.userGuide$.pipe(take(1)).subscribe((guide) => {
      this.setGuideIdentifiers(guide);
      this.setPageTitleAndDescription();
      this.setupLanguages();
    });
  }

  setGuideIdentifiers(guide: Guide | undefined) {
    this.guideId = guide?.id ?? 0;
    this.guideKey = guide?.key?.toLowerCase() ?? '';
  }

  setPageTitleAndDescription() {
    this.setMainTitle();
    this.setMainDescription();
  }

  getLanguages() {
    this.store.dispatch(
      ScSharedActions.getLanguages({ guideId: this.guideId }),
    );
    this.supportedLanguages$ = this.store.pipe(
      select(ScSharedSelectors.languages),
      filterTruthy(),
    );
  }

  preselectLanguage(): void {
    const browserLanguage = window.navigator.language.slice(0, 2).toUpperCase();

    this.supportedLanguages$.pipe(take(1)).subscribe((languages) => {
      if (languages.data)
        this.isLanguageSupported = languages.data.some(
          (language) => language.code === browserLanguage,
        );
      const routeLanguageCode = this.route.snapshot.params?.language;

      if (this.enableGeneralOnboardingScreen && routeLanguageCode) {
        this.selectedLanguage = {
          code: routeLanguageCode,
          display_name:
            languages.data?.find(
              (language) => language.code === routeLanguageCode.toUpperCase(),
            )?.native_name ?? this.defaultLanguage.native_name,
        };
      } else {
        this.selectedLanguage = {
          code: this.isLanguageSupported
            ? browserLanguage
            : this.defaultLanguage.code,
          display_name:
            languages.data?.find(
              (language) => language.code === browserLanguage,
            )?.native_name ?? this.defaultLanguage.native_name,
        };
      }

      this.changeUserLanguage(
        this.selectedLanguage.code,
        this.selectedLanguage.display_name,
      );
    });
  }

  changeUserLanguage(
    selectedLanguageCode: string,
    selectedLanguageName: string,
  ) {
    this.setCurrentLanguage(selectedLanguageCode, selectedLanguageName);
    this.getGuides();

    this.actions
      .pipe(take(1), ofType(ScSharedActions.getUserGuidesSuccess))
      .subscribe((payload) => {
        const currentGuide = this.getSelectedGuide(
          payload.userGuides.data ?? [],
        );

        this.productLineName = currentGuide?.name ?? '';
        const productLineName = formatUrlFriendlyName(this.productLineName);

        this.setGuideIdentifiers(currentGuide);
        this.setPageTitleAndDescription();

        this.router.navigate([
          `../${selectedLanguageCode.toLowerCase()}/${productLineName}`,
        ]);
      });
  }

  setCurrentLanguage(
    selectedLanguageCode: string,
    selectedLanguageName: string,
  ) {
    const languageCode = selectedLanguageCode.toLowerCase();

    this.translateService.use(languageCode);
    this.selectedLanguage = {
      code: languageCode,
      display_name: selectedLanguageName,
    };

    this.languageSelectionChange.emit(this.selectedLanguage.code);
  }

  goToQuestionnaire(guide: Guide): void {
    this.loadingButton[guide.id] = true;

    this.updateLocalStorage();
    this.changeUserLanguage(
      this.selectedLanguage?.code,
      this.selectedLanguage?.display_name,
    );

    this.store.dispatch(
      SlScOnboardingActions.createUserGuid({
        guideId: guide.id,
        language: this.selectedLanguage?.code ?? this.defaultLanguage.code,
      }),
    );

    this.actions
      .pipe(ofType(SlScOnboardingActions.createUserGuidSuccess), take(1))
      .subscribe((payload) => {
        this.userGuid = payload.personal_guide.personal_guide;
        this.navigateToFirstSection(guide.id, this.selectedLanguage.code);
      });
  }

  navigateToFirstSection(guideId: number, selectedLanguage: string) {
    this.store.dispatch(
      SlScOnboardingActions.getSections({
        guideId: guideId,
        language: selectedLanguage ?? this.defaultLanguage.code,
      }),
    );

    const productLineName = formatUrlFriendlyName(this.productLineName);

    this.actions
      .pipe(ofType(SlScOnboardingActions.getSectionsSuccess), take(1))
      .subscribe((payload) => {
        if (payload.sections.data) {
          this.firstSectionName =
            payload.sections?.data[0]?.name?.toLowerCase() ?? '';
        }

        this.router.navigateByUrl(
          `/${this.selectedLanguage?.code}/${productLineName}/${this.userGuid}/questionnaire/${this.firstSectionName}`,
          { state: { questionnaireSectionId: 0 } },
        );
      });
  }

  updateLocalStorage(): void {
    localStorage.removeItem('selectedCountry');
    localStorage.removeItem('selectedRegion');
  }

  reloadCurrentRoute() {
    // eslint-disable-next-line no-console
    console.log('route reload');
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  playVideo() {
    this.videoplayer?.nativeElement.play();
  }

  openConfiguration(onlineCode: string): void {
    this.store.dispatch(SlScOnboardingActions.getUserGuideData({ onlineCode }));

    this.actions
      .pipe(
        ofType(SlScOnboardingActions.getUserGuideDataSuccess),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((payload) => {
        const userGuide = payload.userGuide;
        const lang = userGuide.language_code?.toLocaleLowerCase();
        const productLineName = this.getProductLineName(userGuide);

        this.router.navigateByUrl(
          `/${lang}/${productLineName}/${userGuide.user_guid}/${this.sectionType.toLowerCase()}/${userGuide.summary_section_name}`,
        );
      });

    this.actions
      .pipe(
        ofType(SlScOnboardingActions.getUserGuideDataError),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.snackbar.queue(
          this.translateService.instant(
            'turnkey_solution.onboarding.code_error',
          ),
          {
            type: DsSnackbarType.Error,
          },
        );
      });
  }

  getProductLineName(userGuide: UserGuide): string | null {
    return formatUrlFriendlyName(
      this.userGuides.find((guide) => guide.id === userGuide.guide_id)?.name,
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
