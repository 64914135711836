import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  Guid,
  OnboardingService,
  Sections,
  UserGuide,
} from '@sales-libs/sc-onboarding/data-access';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SlScOnboardingActions } from './onboarding.actions';

@Injectable()
export class SlScOnboardingEffects {
  private readonly actions$: Actions = inject(Actions);

  getSections$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScOnboardingActions.getSections),
      switchMap((payload) =>
        this.onboardingService
          .getSections(payload.guideId, payload.language)
          .pipe(
            map((sectionsPayload: Sections) =>
              SlScOnboardingActions.getSectionsSuccess({
                sections: sectionsPayload,
              }),
            ),
            catchError((err) =>
              of(
                SlScOnboardingActions.getSectionsError({
                  payload: err.error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  createUserGuid$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScOnboardingActions.createUserGuid),
      switchMap((payload) =>
        this.onboardingService
          .createUserGuid(payload.guideId, payload.language)
          .pipe(
            map((guidPayload: Guid) =>
              SlScOnboardingActions.createUserGuidSuccess({
                personal_guide: guidPayload,
              }),
            ),
            catchError((err) =>
              of(
                SlScOnboardingActions.createUserGuidError({
                  payload: err.error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getUserGuideData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SlScOnboardingActions.getUserGuideData),
      switchMap((payload) =>
        this.onboardingService.getUserGuideData(payload.onlineCode).pipe(
          map((userGuidePayload: UserGuide) =>
            SlScOnboardingActions.getUserGuideDataSuccess({
              userGuide: userGuidePayload,
            }),
          ),
          catchError((err) =>
            of(
              SlScOnboardingActions.getUserGuideDataError({
                payload: err.error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(private readonly onboardingService: OnboardingService) {}
}
